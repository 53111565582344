import MessageBroker from '@bonprix/pattern-library/components/message-broker';
import CoreMessages from '@core/scripts/components/message-list';
import openLayer from '@core/scripts/components/layers/open-layer';
import WishlistErrorLayer from '../../components/WishlistErrorLayer.jsx';
import WishlistGlobalModel from '../../components/wishlist-global-model';

import JshModule from '@core/scripts/helper/jsh-module';

JshModule('header/wishlist/wishlist-error-layer');

const {texts} = WishlistGlobalModel.load();

const errorMessages = {
    FULL: {
        headline: texts.bff_wishlist_error_full_headline,
        content: texts.bff_wishlist_error_full_content,
        landmark: 'wishlist_error_layer--full'
    },
    ERROR: {
        headline: texts.bff_wishlist_error_default_headline,
        content: texts.bff_wishlist_error_default_content,
        landmark: 'wishlist_error_layer--default'
    },
    DUPLICATE: {
        headline: texts.bff_wishlist_error_default_headline,
        content: texts.bff_wishlist_error_default_content,
        landmark: 'wishlist_error_layer--duplicate'
    },
    REMOVE: {
        headline: texts.bff_wishlist_error_remove_headline,
        content: texts.bff_wishlist_error_remove_content,
        landmark: 'wishlist_error_layer--remove'
    }
};

MessageBroker.subscribe(CoreMessages.WISHLIST.SHOW_ERROR_LAYER, ({error}) => {
    if (error) {
        const headline = errorMessages[error]?.headline || texts.bff_wishlist_error_default_headline;
        const content = errorMessages[error]?.content || '';
        const landmark = errorMessages[error]?.landmark || errorMessages.ERROR.landmark;
        openLayer(WishlistErrorLayer, {headline, content, landmark});
    }
});

